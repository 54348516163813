import './Loader.css';

interface LoaderProps {
    className?: string;
}

const Loader = (props: LoaderProps) => {
	return (
		<div className={`Loader ${props.className}`}>
            <div className="Loader-wrapper">
                <div className="Loader-icon">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
	);
}

export { Loader };
