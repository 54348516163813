import * as React from 'react';
import { ChangeEventHandler } from 'react';
import './RadioSwitch.css';

interface RadioSwitchProps<T extends string> {
    id: string;
    className?: string;
    name: string;
    labels: string[];
    values: T[];
    defaultValue?: T;
    loginAccountType?: string | null; //value name
    disabled?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const RadioSwitch = React.forwardRef(
    (
        {
            disabled,
            id,
            className,
            name,
            labels,
            values,
            defaultValue,
            loginAccountType,
            onChange,
            ...remainingProps
        }: RadioSwitchProps<string>,
        forwardedRef: any,
    ) => {
        return (
            <fieldset className={`RadioSwitch ${className}`}>
                {values.map((value, index) => (
                    <div className="RadioSwitch-key" key={value}>
                        <input
                            disabled={disabled}
                            id={id + '-' + value}
                            onChange={onChange}
                            className="RadioSwitch-input"
                            name={name}
                            type="radio"
                            value={value}
                            checked={defaultValue == value}
                            ref={forwardedRef}
                            {...remainingProps}
                        />
                        <label htmlFor={id + '-' + value} className="RadioSwitch-label">
                            {labels[index]}
                        </label>
                    </div>
                ))}
            </fieldset>
        );
    },
);

export { RadioSwitch };
