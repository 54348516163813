import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { regex } from '../../common/utils';
import { FloatingLabel } from '../../components/FloatingLabel/FloatingLabel';
import { DotVomEmailWarning } from '../../components/DotVomEmailWarning/DotVomEmailWarning';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { useMutation } from '@tanstack/react-query';
import { accountApi } from '../../common/api/api.accounts';
import { ApiException } from '../../common/api/api.types';
import useLoginContext from '../../hooks/useLoginContext';
import { SpaRoutes } from '../../hooks/useLoginNavigate';

interface LoginEmailProps {
    accountType: 'Private' | 'Company';
}

const LoginEmail = (props: LoginEmailProps) => {
    const context = useLoginContext();
    const { register, handleSubmit, formState, setError, watch } = useForm<formDataEmail>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const returnUrl = context.authState.loginRequest?.returnUrl || context.authState.redirectUrl;

    const signInMutation = useMutation<
        void,
        ApiException,
        {
            email: string;
            password: string;
            accountType: 'Private' | 'Company';
            rememberMe: boolean;
        }
    >({
        mutationFn: (props) =>
            accountApi.signinWithEmailPasswordToApplicationSchema({
                email: props.email,
                password: props.password,
                accountType: props.accountType,
                rememberMe: props.rememberMe,
            }),
        onError: (e => {
            switch (e.code) {
                case 'USE_EXTERNAL_SIGNIN':
                    window.location.href = SpaRoutes.BuildChallengeExternalUrl(e.field, returnUrl);
                    break;
            }
        }),
        onSuccess: () => {
            window.location.href = returnUrl;
        },
    });

    const handleUsernameFormSubmit = (formData: formDataEmail) => {
        if (formState.isValid) {
            signInMutation.mutate({
                email: formData.Email,
                password: formData.Password,
                accountType: props.accountType,
                rememberMe: formData.RememberMe,
            });
        }
    };

    const isLoading = signInMutation.isPending || signInMutation.isSuccess;

    return (
        <form className="Form" onSubmit={handleSubmit(handleUsernameFormSubmit)}>
            <FloatingLabel
                id="loginEmail"
                className="u-marginTlg"
                label="E-postadress"
                type="email"
                disabled={isLoading}
                autoFocus={true}
                autoComplete="email"
                formState={formState}
                {...register('Email', {
                    required: 'Ange e-postadress',
                    pattern: {
                        value: regex.emailSpaceAtEnd,
                        message: 'Angiven e-postadress har fel format.',
                    },
                })}
            />
            <DotVomEmailWarning value={watch('Email')} />
            <FloatingLabel
                id="loginPassword"
                className="u-marginTmd"
                label="Lösenord"
                type="password"
                autoComplete="off"
                formState={formState}
                disabled={isLoading}
                {...register('Password', { required: 'Ange lösenord' })}
            />
            <Checkbox
                id="loginRememberMe"
                className="u-marginT u-marginBz"
                label="Kom ihåg mig"
                formState={formState}
                checked
                disabled={isLoading}
                {...register('RememberMe')}
            />
            <footer className="u-marginTauto">
                {signInMutation.isError && (
                    <p className="Notice Notice--red">{signInMutation.error.friendlyMessage}</p>
                )}
                <button
                    className="Button Button--green u-marginT"
                    disabled={!formState.isValid || isLoading}
                    type="submit"
                >
                    Logga in
                </button>

                <p className="u-textCenter u-marginT u-marginBz u-textSmall">
                    <Link
                        className="Link"
                        to={{
                            pathname: '/glomt-losenord',
                            search: window.location.search,
                        }}
                    >
                        Glömt lösenord?
                    </Link>
                </p>
                <p className="u-textCenter u-marginTxsm u-marginBz u-textSmall">
                    {props.accountType === 'Company' ? (
                        <Link
                            className="Link"
                            to={{
                                pathname: '/skapa-kundkonto/foretag',
                                search: window.location.search,
                            }}
                        >
                            Skapa ett företagskonto
                        </Link>
                    ) : (
                        <Link
                            className="Link"
                            to={{
                                pathname: '/skapa-kundkonto',
                                search: window.location.search,
                            }}
                        >
                            Skapa ett kundkonto
                        </Link>
                    )}
                </p>
                <EmployeeLogin
                    email={watch('Email')}
                    returnUrl={context.authState.loginRequest?.returnUrl}
                />
            </footer>
        </form>
    );
};

const EmployeeLogin = (props: { email?: string; returnUrl?: string }) => {
    if (!props.email?.length || !props.returnUrl?.length) {
        return null;
    }
    if (!props.email.endsWith('@kf.se') && !props.email.endsWith('@coop.se')) {
        return null;
    }

    return (
        <p className="u-textCenter u-marginTxsm u-marginBz u-textSmall">
            <a
                className="Link "
                href={`/externalauthentication/challange/CoopAzureAd?returnUrl=${encodeURIComponent(
                    props.returnUrl,
                )}`}
            >
                Logga in med Microsoft konto
            </a>
        </p>
    );
};

interface formDataEmail {
    Email: string;
    Password: string;
    RememberMe: boolean;
    LoginAccounType?: string;
}

export default LoginEmail;
