interface Colors {
    white: string;
    black: string;
    border: string;
    grayLight: string;
    grayMid: string;
    grayDark: string;
    green: string;
    greenDark: string;
    red: string;
    colorDisabled: string;
}

const colors: Colors = {
    white: "#ffffff",
    black: "#333333",
    border: "#ededed",
    grayLight: "#fdfdfd",
    grayMid: "#f5f5f5",
    grayDark: "#777777",
    green: "#00aa46",
    greenDark: "#005537",
    red: "#c74423",
    colorDisabled: "f0eee7",
};

export default colors;
