import React from 'react';
import { getCoopseLoginUrl } from '../../common/utils';
import { Loader } from '../../components/Loader/Loader';
import { AppContext } from '../App';
import '../../components/Link/Link.css';
import { AuthenticationStateResult } from '../../common/api/api.types';

const Logout = (props: { authState: AuthenticationStateResult }) => {
    const [isLoggedOut, setIsLoggedOut] = React.useState(false);
    const context = React.useContext(AppContext);
    React.useEffect(() => {
        if (isLoggedOut) {
            window.location.href = props.authState.logoutRedirectUrl;
        }
    }, [isLoggedOut, props.authState.logoutRedirectUrl]);

    React.useEffect(() => {
        context?.setShowNavigation(false);
    }, [context]);

    React.useEffect(() => {
        document.title = 'Coop | Logga ut';
    }, []);

    if (!props.authState.signOutIFrame && props.authState.logoutRedirectUrl) {
        if (!isLoggedOut) {
            setIsLoggedOut(true);
        }

        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">
                    {isLoggedOut ? 'Utloggad' : 'Vänta medan vi loggar ut dig...'}
                </h1>
            </>
        );
    }

    return props.authState.signOutIFrame ? (
        <>
            <h1 className="Heading Heading--large u-textCenter">
                {isLoggedOut ? 'Utloggad' : 'Vänta medan vi loggar ut dig...'}
            </h1>
            <Loader className="u-marginT" />
            <iframe
                src={props.authState.signOutIFrame}
                onLoad={() => setIsLoggedOut(true)}
                style={{ height: 0, visibility: 'hidden' }}
                title="Loggar ut dig"
            ></iframe>
        </>
    ) : (
        <>
            <h1 className="Heading Heading--large u-textCenter">Utloggning misslyckades</h1>
            <p className="u-textCenter">
                Vänligen{' '}
                <a
                    className="Link"
                    href={getCoopseLoginUrl()}
                    onClick={(event) => {
                        window.history.length && window.history.back();
                        event.preventDefault();
                    }}
                >
                    gå tillbaka
                </a>{' '}
                och försök igen.
            </p>
        </>
    );
};

export { Logout };
