import LoginEmail from '../LoginEmail';
import useLoginContext from '../../../hooks/useLoginContext';

const LoginCompany = () => {
    const appContext = useLoginContext();
    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Logga in företag</h1>
            <LoginEmail accountType={'Company'} />
        </>
    );
};
export default LoginCompany;
