import { useLocation } from "react-router-dom";
import { useQuerySearchParams } from "../../hooks/useQuerySearchParams";
import { FC } from "react";
import { Wrapper } from "./style";

interface PageResult {
    totalPages: number;
    pages: Page[];
}

export interface Page {
    pageNumber: number;
    startIndex: number;
    resultsPerPage: number;
    isCurrentPage: boolean;
    isComplementaryPage?: boolean;
}

const calculatePaging = (
    currentPage: number,
    totalResults: number,
    resultsPerPage: number,
    range: number
): PageResult => {
    const totalPages = Math.ceil(totalResults / resultsPerPage);
    if (range === 0) range = 1;

    // Since both sides of the pagenumber you are on needs to be same, the range number must be odd
    if (range % 2 === 0) {
        range += 1;
    }

    if (range > totalPages) range = totalPages;
    if (currentPage <= 0) currentPage = 1;

    const rangePerSide = Math.floor(range / 2);
    let start = currentPage - rangePerSide;
    start = Math.max(start, 1);
    start = Math.min(start, 1 + totalPages - range);

    const leftComplement = start > 1;
    const rightComplement = start + range - 1 < totalPages;

    const pages: Page[] = [];

    for (let i = 0; i < range; i += 1) {
        pages.push({
            pageNumber: start + i,
            isCurrentPage: start + i === currentPage,
            resultsPerPage,
            startIndex: (start + i - 1) * resultsPerPage,
        });
    }

    if (leftComplement) {
        pages.splice(0, rangePerSide - 1, {
            pageNumber: 1,
            isCurrentPage: false,
            isComplementaryPage: true,
            resultsPerPage,
            startIndex: 0,
        });
    }

    if (rightComplement) {
        pages.splice(pages.length - rangePerSide + 1, rangePerSide, {
            pageNumber: totalPages,
            isCurrentPage: false,
            isComplementaryPage: true,
            resultsPerPage,
            startIndex: (totalPages - 1) * resultsPerPage,
        });
    }

    return {
        pages,
        totalPages,
    };
};

interface PageNumberProps {
    page: Page;
    pagingResult: PageResult;
}
const PageNumber: FC<React.PropsWithChildren<PageNumberProps>> = ({
    page,
    pagingResult,
}) => {
    if (!page) return null;

    if (page.isComplementaryPage && page.pageNumber === 1) {
        if (
            pagingResult.pages.find((x) => x.pageNumber === page.pageNumber + 1)
        ) {
            return <>{page.pageNumber}</>;
        }
        return (
            <>
                {page.pageNumber} <span className="u-marginLmd">...</span>
            </>
        );
    }

    if (page.isComplementaryPage && page.pageNumber !== 1) {
        if (
            pagingResult.pages.find((x) => x.pageNumber === page.pageNumber - 1)
        ) {
            return <>{page.pageNumber}</>;
        }
        return (
            <>
                <span className="u-marginRmd">...</span> {page.pageNumber}
            </>
        );
    }

    if (!page.isComplementaryPage) {
        return <>{page.pageNumber}</>;
    }

    return null;
};

interface PaginationProps {
    currentPage: number;
    totalResults: number;
    resultsPerPage: number;
    paginationRange: number;
    onPageChange: (e: React.MouseEvent<HTMLAnchorElement>, page: Page) => void;
    zeroIndexBased?: boolean;
    hideIfOnlyOnePage?: boolean;
}

const Pagination: FC<React.PropsWithChildren<PaginationProps>> = (
    props: PaginationProps
) => {
    const { currentPage } = props;
    const pagingResult = calculatePaging(
        currentPage,
        props.totalResults,
        props.resultsPerPage,
        props.paginationRange
    );

    const location = useLocation();
    const queryParams = useQuerySearchParams();

    const buildUrlWithPage = (page: Page) => {
        if (page?.pageNumber > 1) {
            queryParams.set("page", page.pageNumber.toString());
        } else {
            queryParams.delete("page");
        }
        const newUrl = `${location.pathname}${
            queryParams.toString() && `?${queryParams.toString()}`
        }`;
        return newUrl;
    };

    const getPage = (pageNumber: number) => {
        return pagingResult.pages.find(
            (page) => page.pageNumber === pageNumber
        );
    };

    const prevPage = getPage(currentPage - 1);
    const nextPage = getPage(currentPage + 1);

    if (pagingResult.totalPages === 0) {
        return null;
    }

    if (pagingResult.totalPages === 1 && props.hideIfOnlyOnePage) {
        return null;
    }

    return (
        <Wrapper>
            {currentPage > 1 && prevPage && (
                <a
                    href={buildUrlWithPage(prevPage)}
                    className="Pagination-arrow u-outlineSolidBase2 u-marginHxxxsm"
                    onClick={(e) => props.onPageChange(e, prevPage)}
                    rel="nofollow"
                >
                    &lt;
                </a>
            )}
            {pagingResult.pages.map((page) => (
                <a
                    href={buildUrlWithPage(page)}
                    key={page.pageNumber}
                    className={`Pagination-page u-outlineSolidBase2 u-marginHxxxsm u-md-paddingAsm u-xsm-paddingAxsm ${
                        page.isCurrentPage ? "is-active" : ""
                    }`}
                    onClick={(e) => props.onPageChange(e, page)}
                    rel={page?.pageNumber > 3 ? "nofollow" : undefined}
                >
                    <PageNumber page={page} pagingResult={pagingResult} />
                </a>
            ))}
            {currentPage !== pagingResult.totalPages && nextPage && (
                <a
                    href={buildUrlWithPage(nextPage)}
                    className="Pagination-arrow u-outlineSolidBase2 u-marginHxxxsm"
                    onClick={(e) => props.onPageChange(e, nextPage)}
                    rel="nofollow"
                >
                    &gt;
                </a>
            )}
        </Wrapper>
    );
};

export default Pagination;
