import React, { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getCoopseLoginUrl } from '../common/utils';
import { ForgotPassword } from './glomt-losenord/ForgotPassword';
import { AccountRemoved } from '../components/AccountRemoved/AccountRemoved';
import { AccessDenied } from '../components/AccessDenied/AccessDenied';
import { LoggedIn } from '../components/LoggedIn/LoggedIn';
import { Logout } from './logga-ut/Logout';
import '../components/Button/Button.css';
import CloseIcon from '../icons/close.svg?react';
import BackIcon from '../icons/arrow-left.svg?react';
import './App.css';
import '../common/utils.css';
import { PunchoutSignin } from '../components/Punchout/components/PunchoutSignin';
import dispatchEvent from '../lib/dispatchEvent';
import { useAuthState } from '../hooks/useAuthState';
import { AuthenticationStateResult } from '../common/api/api.types';
import { Loader } from '../components/Loader/Loader';
import { isLoginStateBankidOnly } from '../core/authState.utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CreateNewAccount from './skapa-kundkonto/CreateNewAccount';
import { Login } from './logga-in/Login';
import ChangePassword from './andra-losenord/ChangePassword';
import ResetPassword from './aterstall-losenord/ResetPassword';
import RemoveAccount from './radera-kundkonto/RemoveAccount';
import { SpaRoutes } from '../hooks/useLoginNavigate';
import ChangeEmail from './andra-email/ChangeEmail';
import Confirm from './bekrafta/Confirm';
import LoginOmbudshandel from './logga-in/LoginOmbudshandel';
import ErrorPage from './error/ErrorPage';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    },
});

interface IAppContext {
    backUrl: string;
    setBackUrl: React.Dispatch<React.SetStateAction<string>>;
    showNavigation: boolean;
    setShowNavigation: React.Dispatch<React.SetStateAction<boolean>>;
    showBackIcon: boolean;
    setShowBackIcon: React.Dispatch<React.SetStateAction<boolean>>;
    bankIdOnly: boolean;
    authState: AuthenticationStateResult;
    refetchAuthState: () => Promise<void>;
}

const AppContext = React.createContext<IAppContext | null>(null);

const App = () => {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <LoadApp />
            </QueryClientProvider>
        </BrowserRouter>
    );
};

const LoadApp = () => {
    const [backUrl, setBackUrl] = useState('');
    const [showNavigation, setShowNavigation] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showBackIcon, setShowBackIcon] = useState(false);
    const { data: authState, isError, isSuccess, refetch } = useAuthState();

    const bankIdOnly = isSuccess && isLoginStateBankidOnly(authState);
    const isOmbudshandel = !!authState?.loginRequest?.clientId?.startsWith('kommunal-admin');
    const refetchAuthState = async () => {
        await refetch();
    };
    if (isError) {
        return (
            <div className="App App--noNav">
                <div className="App-content">
                    <ErrorPage />
                </div>
            </div>
        );
    }

    if (!authState) {
        return (
            <div className="App App--noNav">
                <div className="App-content">
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <AppContext.Provider
            value={{
                backUrl: backUrl,
                setBackUrl: setBackUrl,
                showNavigation: showNavigation,
                setShowNavigation: setShowNavigation,
                showBackIcon: showBackIcon,
                setShowBackIcon: setShowBackIcon,
                bankIdOnly: bankIdOnly,
                authState: authState,
                refetchAuthState,
            }}
        >
            <Routes>
                <Route path="/punchout/signin" element={<PunchoutSignin authState={authState} />} />
                <Route
                    path="*"
                    element={
                        <div className={`App  ${showNavigation ? '' : 'App--noNav'}`}>
                            {authState && (
                                <AppHeader scrollPosition={scrollPosition} authState={authState} />
                            )}
                            <div
                                className="App-content"
                                onScroll={(event) =>
                                    setScrollPosition((event.target as HTMLElement).scrollTop)
                                }
                            >
                                <Routes>
                                    <Route
                                        path="/andra-losenord/*"
                                        element={<ChangePassword />}
                                    ></Route>
                                    <Route path="/andra-email/*" element={<ChangeEmail />}></Route>
                                    <Route
                                        path="/kontot-borttaget"
                                        element={<AccountRemoved />}
                                    ></Route>
                                    <Route
                                        path="/aterstall-losenord"
                                        element={<ResetPassword />}
                                    ></Route>
                                    <Route path="/bekrafta/*" element={<Confirm />}></Route>
                                    <Route
                                        path="/bekrafta-bankid"
                                        element={
                                            <Navigate
                                                to={{
                                                    pathname: SpaRoutes.Confirm.Bankid,
                                                    search: location.search,
                                                }}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/bekrafta-losenord"
                                        element={
                                            <Navigate
                                                to={{
                                                    pathname: SpaRoutes.Confirm.Password,
                                                    search: location.search,
                                                }}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="/Account/AccessDenied"
                                        element={<AccessDenied />}
                                    ></Route>
                                    <Route
                                        path="/glomt-losenord"
                                        element={<ForgotPassword />}
                                    ></Route>
                                    <Route path="/error" element={<ErrorPage />}></Route>
                                    <Route
                                        path="/logga-in/*"
                                        element={isOmbudshandel ? <LoginOmbudshandel /> : <Login />}
                                    ></Route>
                                    <Route
                                        path="/logga-ut"
                                        element={<Logout authState={authState} />}
                                    ></Route>
                                    <Route
                                        path="/radera-kundkonto/*"
                                        element={<RemoveAccount />}
                                    ></Route>
                                    <Route
                                        path="/skapa-kundkonto/*"
                                        element={<CreateNewAccount />}
                                    ></Route>
                                    <Route path="/" element={<LoggedIn />}></Route>
                                    <Route
                                        path="/*"
                                        element={
                                            <Navigate to={{ pathname: '/logga-in' }} replace />
                                        }
                                    ></Route>
                                </Routes>
                            </div>
                        </div>
                    }
                />
            </Routes>
        </AppContext.Provider>
    );
};

interface AppHeaderProps {
    scrollPosition: number;
    authState: AuthenticationStateResult;
}

const AppHeader = (props: AppHeaderProps) => {
    const location = useLocation();

    const context = React.useContext(AppContext);
    const cancelAppUrl = props.authState.loginRequest?.cancelAppUrl;
    let navigate = useNavigate();

    const handleBack = () => {
        if (context?.backUrl) {
            navigate(context.backUrl);
        } else {
            navigate(-1);
        }
    };

    const handleBankIdBack = () => {
        context?.setShowBackIcon(!context.showBackIcon);
    };

    const handleClose = () => {
        dispatchEvent('app-window-close-' + validMobileAppEvents(location.pathname));
        window.location.href = getCoopseLoginUrl();
    };

    // Reset some context states on location change, no need to do it in every view.
    React.useEffect(() => {
        context?.setBackUrl('');
        context?.setShowNavigation(true);
    }, [location, context]);

    return (
        <header
            className={`App-header ${props.scrollPosition > 0 ? 'App-header--withBoxShadow' : ''}`}
        >
            {context?.showNavigation && (
                <nav className="App-nav">
                    {context.bankIdOnly && !!cancelAppUrl ? (
                        <>
                            {context?.showBackIcon && (
                                <button
                                    type="button"
                                    className="Button App-navBack"
                                    onClick={handleBankIdBack}
                                >
                                    <BackIcon
                                        className="App-navIcon"
                                        fill="#333"
                                        title="Tillbaka"
                                    />
                                </button>
                            )}
                            <a
                                href={!!cancelAppUrl ? cancelAppUrl : (window.location.href = '/')}
                                className="Button App-navClose"
                            >
                                <CloseIcon className="App-navIcon" fill="#333" title="Stäng" />
                            </a>
                        </>
                    ) : (
                        <>
                            {location.pathname !== '/' && context?.backUrl && (
                                <button
                                    type="button"
                                    className="Button App-navBack"
                                    onClick={handleBack}
                                >
                                    <BackIcon
                                        className="App-navIcon"
                                        fill="#333"
                                        title="Tillbaka"
                                    />
                                </button>
                            )}
                            <button
                                type="button"
                                className="Button App-navClose"
                                onClick={handleClose}
                            >
                                <CloseIcon className="App-navIcon" fill="#333" title="Stäng" />
                            </button>
                        </>
                    )}
                </nav>
            )}
        </header>
    );
};

/** The mobile app is just listening to some special close events (should be change to all that starts with app-window-close-). */
const validMobileAppEvents = (location: string) => {
    if (!location) {
        return 'logga-in';
    }
    if (location.startsWith('/andra-email')) {
        return 'andra-email';
    }

    return location.replace('/', '');
};

export { App, AppContext };
