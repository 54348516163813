import * as React from 'react';
import '../Checkbox/Checkbox.css';

interface CheckboxProps {
    id: string;
    className?: string;
    name: string;
    label: string;
    disabled?: boolean;
    helperText?: string | React.ReactElement;
    checked?: boolean;
    formState?: any;
}

const Checkbox = React.forwardRef(({id, className, name, label, checked, disabled, helperText, formState, ...remainingProps}: CheckboxProps, forwardedRef: any) => {
    return (
        <>
            <div className={`Checkbox ${className ? className : ''}`}>
                <input
                    id={id}
                    className={`
                        Checkbox-input ${formState.errors[name] ? 'is-invalid' : ''}
                    `}
                    name={name}
                    type="checkbox"
                    defaultChecked={checked}
                    disabled={disabled}
                    ref={forwardedRef}
                    {...remainingProps}
                />
                <label htmlFor={id} className="Checkbox-label">{label}</label>
            </div>
            {formState.errors[name] && formState.errors[name]?.message && (
                <p className="Checkbox-validationMessage" data-valmsg-for={id}>
                    {formState.errors[name].message}
                </p>
            )}
            {helperText && <p className="Checkbox-helperText">{helperText}</p>}
        </>
    );
});

export { Checkbox };
