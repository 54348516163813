import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { ApiException } from '../../../../../common/api/api.types';
import useLoginNavigate, { SpaRoutes } from '../../../../../hooks/useLoginNavigate';
import { accountApi } from '../../../../../common/api/api.accounts';
import useLoginContext from '../../../../../hooks/useLoginContext';
import useExternalUser from '../../../useExternalUser';


const EmailNew = () => {
    const context = useLoginContext();
    const navigate = useLoginNavigate();
    let { email } = useParams();
    const sendVerificationMailMutation = useMutation<void, ApiException, string>({
        mutationFn: accountApi.createAccountSendEmailVerificationCode,
        onSuccess: () => {
            if (email) {
                navigate(SpaRoutes.CreateAccount.Private.BankId.VerifyNewEmail(email));
            }
        },
    });

    const sendEmailVerificationClick = () => {
        if (email) {
            sendVerificationMailMutation.mutateAsync(email);
        }
    };

    const isLoading = sendVerificationMailMutation.isPending;    

    let emailExist = true;
    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Skapa kundkonto</h1>
            <p className="u-textCenter">
                För att skapa ett kundkonto behöver du godkänna villkoren och verifiera din e-postadress.
            </p>
            <p className="u-textCenter">
                <strong>{email}</strong>
            </p>
            <p className="u-textXSmall u-colorGray u-marginTmd u-textCenter">
                Genom att skapa ett kundkonto godkänner du{' '}
                <a
                    className="Link"
                    href="https://www.coop.se/globala-sidor/anvandarvillkor/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Coops villkor
                </a>
                .{' '}
                <a
                    className="Link"
                    href="https://www.coop.se/Globala-sidor/Om-webbplatsen/personuppgifter/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Klicka här
                </a>{' '}
                för att läsa om hur Coop hanterar din personliga information.
            </p>
            {sendVerificationMailMutation.isError && (
                <p className="Notice Notice--red">
                    {sendVerificationMailMutation.error.friendlyMessage}
                </p>
            )}
            <footer className="u-marginTauto">
                <button
                    disabled={isLoading}
                    className="Button Button--green u-marginT"
                    type="button"
                    onClick={sendEmailVerificationClick}
                >
                    Skicka verifikationskod
                </button>
                <button
                    disabled={isLoading}
                    className="Button Button--invertedGreenDark u-marginTmd"
                    type="button"
                    onClick={() => navigate(SpaRoutes.CreateAccount.Private.BankId.RegisterEmail)}
                >
                    Ange annan e-postadress
                </button>
            </footer>
        </>
    );
};

export default EmailNew;
