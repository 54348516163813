import React from "react";

import { Text, Wrapper } from "./style";

export interface ButtonProps {
    title?: string;
    icon?: React.ReactNode;
    size?: "small" | "large";
    theme?: "inverted" | "greenDark" | "red" | "redInverted";
    shape?: "round";
    disabled?: boolean;
}
interface Props extends ButtonProps {
    onClick: () => void;
    isLoading?: boolean;
}

const Button: React.FC<Props> = ({
    title,
    icon,
    isLoading,
    onClick,
    shape,
    theme,
    size,
    disabled,
}) => {
    return (
        <Wrapper
            onClick={onClick}
            title={title}
            shape={shape}
            theme={theme}
            size={size}
            disabled={disabled}>
            {title && <Text isLoading={isLoading}>{title}</Text>}
            {icon && icon}
        </Wrapper>
    );
};

export default Button;
