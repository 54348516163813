import { AxiosRequestConfig } from 'axios';
import { ApiException } from './api.types';
import { getCookie } from '../utils';

export const isApiException = (object: unknown): object is ApiException => {
    return !!object && (object as ApiException).friendlyMessage !== undefined;
};

export const addXsrfTokenToHeader = (config: any) => {
    // Do something before request is sent
    config.headers.set('RequestVerificationToken', getCookie('XSRF-TOKEN') || '');
    return config;
};
