import { Navigate, Route, Routes } from 'react-router-dom';
import { FloatingLabel } from '../../../components/FloatingLabel/FloatingLabel';
import useLoginNavigate, { SpaRoutes } from '../../../hooks/useLoginNavigate';
import { ChangeEvent } from 'react';
import CreateAccountBankid from './bankid/CreateAccountBankid';
import CreateEmailAccount from './e-post/CreateEmailAccount';
import { VerifyEmail } from './e-post/verifiera-epost/Verifymail';

const headerRoutes = [
    SpaRoutes.CreateAccount.Private.BankId.Start,
    SpaRoutes.CreateAccount.Private.Email.Start,
];

export const CreateNewAccountPrivate = () => {
    const navigate = useLoginNavigate();
    const onTypeChange = (e?: ChangeEvent<HTMLInputElement>) => {
        if (e?.target.id === 'loginBankId') {
            navigate(SpaRoutes.CreateAccount.Private.BankId.Start);
        } else {
            navigate(SpaRoutes.CreateAccount.Private.Email.Start);
        }
    };
    const isBankIdRoute = location.pathname.startsWith(
        SpaRoutes.CreateAccount.Private.BankId.Start,
    );
    // lets trim ending slash
    const path = location.pathname.replace(/\/$/, '');
    return (
        <Routes>
            <Route path="/e-post/verifiera" element={<VerifyEmail />} />
            <Route
                path="*"
                element={
                    <>
                        {headerRoutes.includes(path) && (
                            <>
                                <h1 className="Heading Heading--large u-textCenter">
                                    Skapa kundkonto
                                </h1>

                                <div className="u-flex u-flexJustifyCenter u-gapSm">
                                    <FloatingLabel
                                        id="loginBankId"
                                        className="u-marginTlg"
                                        label="Med BankID"
                                        type="radio"
                                        utils="u-visibility-left"
                                        autoFocus={true}
                                        defaultChecked={isBankIdRoute}
                                        name="identifierType"
                                        onChange={onTypeChange}
                                    />
                                    <FloatingLabel
                                        id="loginUsernamePassword"
                                        className="u-marginTlg"
                                        utils="u-visibility-left"
                                        label="Med E-post"
                                        type="radio"
                                        defaultChecked={!isBankIdRoute}
                                        name="identifierType"
                                        onChange={onTypeChange}
                                    />
                                </div>
                            </>
                        )}
                        <Routes>
                            <Route path={'/bankid/*'} element={<CreateAccountBankid />} />
                            <Route path={'/e-post/*'} element={<CreateEmailAccount />} />
                            <Route
                                path={'/*'}
                                element={
                                    <Navigate
                                        to={{
                                            pathname: SpaRoutes.CreateAccount.Private.BankId.Start,
                                            search: location.search,
                                        }}
                                    />
                                }
                            />
                        </Routes>
                    </>
                }
            />
        </Routes>
    );
};
