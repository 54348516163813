import styled, { css } from 'styled-components';

import colors from '../../../../common/colors';
import mixins from '../../../../common/mixins';
import { fontSize, space } from '../../../../common/variables';

export const Wrapper = styled.div<{ outline: boolean | undefined }>`
    display: flex;
    position: relative;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    border: ${(props) => (props.outline ? `1px solid ${colors.grayDark}` : `none`)};
`;

export const Input = styled.input`
    width: 100%;
    padding: ${space.medium} 140px ${space.medium} ${space.medium};
    font-size: ${fontSize.xLarge};
    border-radius: 5px;
    border: none;
    outline: none;
    transition: box-shadow 0.3s;

    &:focus {
        box-shadow: ${mixins.boxShadow.wide};
    }

    &::-webkit-input-placeholder {
        font-size: ${fontSize.small};
    }
`;

export const Placeholder = styled.span`
    position: absolute;
    color: ${colors.grayDark};
    font-size: ${fontSize.small};
    left: ${space.medium};
    top: 50%;
    transform: translateY(-50%);
`;

export const SearchButton = styled.div<{ query: string }>`
    position: absolute;
    right: ${space.medium};
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s, visibility 0.3s;

    ${(props) =>
        props.query.length > 0
            ? css`
                  opacity: 1;
                  visibility: visible;
              `
            : css`
                  opacity: 0;
                  visibility: hidden;
              `};
`;

export const Cover = styled.div`
    position: absolute;
    color: ${colors.grayDark};
    font-size: ${fontSize.small};
    left: ${space.medium};
    top: 50%;
    pointer-events: none;
    transform: translateY(-50%);
`;
