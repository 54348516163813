import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FloatingLabel } from '../../components/FloatingLabel/FloatingLabel';
import { getCookie, regex } from '../../common/utils';
import { useResponseErrorHandling } from '../../hooks/useResponseErrorHandling';
import '../../components/Link/Link.css';
import '../../components/Form/Form.css';
import '../../components/Notice/Notice.css';
import { DotVomEmailWarning } from '../../components/DotVomEmailWarning/DotVomEmailWarning';
import { accountApi } from '../../common/api/api.accounts';
import { useMutation } from '@tanstack/react-query';
import { ApiException } from '../../common/api/api.types';

type FormData = { Email: string };
const ForgotPassword = () => {
    const { register, handleSubmit, formState, watch, getValues } = useForm<FormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const [sentEmail, setSentEmail] = useState<string>();
    const forgotPasswordMutation = useMutation<void, ApiException, { email: string }>({
        mutationFn: accountApi.sendForgotPasswordEmail,
    });

    const handleForgotPasswordFormSubmit = (formData: FormData) => {
        if (formState.isValid) {
            forgotPasswordMutation.mutate({ email: formData.Email });
            setSentEmail(formData.Email);
        }
    };

    React.useEffect(() => {
        document.title = 'Coop | Glömt lösenord';
    }, []);

    if (forgotPasswordMutation.isSuccess) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">Glömt lösenord?</h1>

                <p className="u-textCenter">
                    Vi har skickat ett e-postmeddelande till{' '}
                    <strong className="u-textNoWrap">{sentEmail}</strong> med information om hur du
                    återställer ditt lösenord.
                </p>
                <p className="u-textCenter">
                    Kommer du inte åt inboxen eller har något annat problem,{' '}
                    <a
                        className="Link"
                        href="https://www.coop.se/Globala-sidor/Kundservice/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        kontakta Kundservice
                    </a>
                    .
                </p>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Glömt lösenord?</h1>

            <p className="u-textCenter">
                Fyll i din e-postadress så skickar vi en länk för att återställa ditt lösenord.
                Länken är giltig i 24 timmar.
            </p>
            <form className="Form" onSubmit={handleSubmit(handleForgotPasswordFormSubmit)}>
                <FloatingLabel
                    id="email"
                    className="u-marginTlg"
                    label="E-postadress"
                    type="email"
                    autoFocus={true}
                    formState={formState}
                    {...register('Email', {
                        required: 'Ange e-postadress',
                        pattern: {
                            value: regex.emailSpaceAtEnd,
                            message: 'Angiven e-postadress har fel format',
                        },
                    })}
                />
                <DotVomEmailWarning value={watch('Email')} />
                <footer className="u-marginTauto">
                    {forgotPasswordMutation.isError && (
                        <p className="Notice Notice--red">
                            {forgotPasswordMutation.error.friendlyMessage}
                        </p>
                    )}
                    <button
                        className="Button Button--green u-marginT"
                        type="submit"
                        disabled={!formState.isValid || forgotPasswordMutation.isPending}
                    >
                        Återställ lösenord
                    </button>
                </footer>
            </form>
        </>
    );
};

export { ForgotPassword };
