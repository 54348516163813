import React from 'react';
import { useForm } from 'react-hook-form';
import { getCoopseLoginUrl, regex } from '../../common/utils';
import { FloatingLabel } from '../../components/FloatingLabel/FloatingLabel';
import { PasswordStrengthIndicator } from '../../components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import { useMutation } from '@tanstack/react-query';
import { ApiException } from '../../common/api/api.types';
import { accountApi } from '../../common/api/api.accounts';

interface FormFields {
    Password: string;
    ConfirmPassword: string;
}

const ResetPassword = () => {
    var searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const code = searchParams.get('code');
    const { register, handleSubmit, formState, watch, getValues } = useForm<FormFields>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });

    const resetPasswordClick = (form: FormFields) => {
        if (formState.isValid && email && code) {
            resetPasswordMutation.mutate({
                email: email,
                code: code,
                confirmPassword: form.ConfirmPassword,
                password: form.Password,
            });
        }
    };
    const resetPasswordMutation = useMutation<
        void,
        ApiException,
        {
            password: string;
            confirmPassword: string;
            email: string;
            code: string;
        }
    >({
        mutationFn: (params) =>
            accountApi.resetPassword({
                email: params.email,
                code: params.code,
                password: params.password,
                confirmPassword: params.confirmPassword,
            }),
    });
    React.useEffect(() => {
        document.title = 'Coop | Återställ lösenord';
    }, []);

    if (resetPasswordMutation.isSuccess) {
        return (
            <div className="App-content">
                <h1 className="Heading Heading--large u-textCenter">Återställ lösenord</h1>
                <p className="u-textCenter u-marginTlg">
                    Ditt lösenord för <strong className="u-textNoWrap">{email}</strong> är nu
                    ändrat.
                </p>
                <p className="u-textCenter">Glöm inte att anteckna ditt lösenord.</p>
                <p className="u-textCenter">
                    <a className="Link" href={getCoopseLoginUrl()}>
                        Gå vidare till Coop.se
                    </a>
                </p>
            </div>
        );
    }

    if (!email || !code) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">Återställ lösenord</h1>
                <p className="Notice Notice--red">
                    Länken du kom hit med verkar inte vara giltig längre, försök igen. Felkod 3222.
                </p>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter u-marginBlg">Återställ lösenord</h1>
            <form className="Form" onSubmit={handleSubmit(resetPasswordClick)}>
                <FloatingLabel
                    id="password"
                    className="u-marginTmd"
                    label="Nytt lösenord"
                    type="password"
                    autoComplete="off"
                    formState={formState}
                    {...register('Password', {
                        required: 'Ange lösenord.',
                        pattern: {
                            value: regex.password,
                            message: 'Lösenordet måste bestå av minst 6 tecken.',
                        },
                    })}
                />
                <PasswordStrengthIndicator className="u-marginTmd" value={watch('Password')} />

                <FloatingLabel
                    id="confirmPassword"
                    className="u-marginTmd"
                    label="Bekräfta nytt lösenord"
                    type="password"
                    autoComplete="off"
                    formState={formState}
                    {...register('ConfirmPassword', {
                        required: 'Ange lösenord.',
                        validate: (value) =>
                            value === getValues('Password') || 'Lösenorden matchar inte varandra.',
                    })}
                />
                <footer className="u-marginTauto">
                    {resetPasswordMutation.isError && (
                        <p className="Notice Notice--red  u-marginTmd">
                            {resetPasswordMutation.error.friendlyMessage}
                        </p>
                    )}
                    <button
                        disabled={resetPasswordMutation.isPending || !formState.isValid}
                        className="Button Button--green u-marginTmd"
                        type="submit"
                    >
                        Ändra lösenord
                    </button>
                </footer>
            </form>
        </>
    );
};

export default ResetPassword;
