import React from 'react';
import { getCoopseLoginUrl } from '../../common/utils';
import { AppContext } from '../../pages/App';
import '../Button/Button.css';
import { useAuthState } from '../../hooks/useAuthState';

const LoggedIn = () => {
    const context = React.useContext(AppContext);
    const { data: authState } = useAuthState();
    React.useEffect(() => {
        context?.setShowNavigation(false);
    }, [context]);

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Inloggad som</h1>
            <p className="u-textCenter u-marginTmd">
                <strong>{authState?.currentUser?.email}</strong>
            </p>
            <footer className="u-marginTauto">
                <p className="u-textCenter u-marginT">
                    <a href={getCoopseLoginUrl()} className="Button Button--green">
                        Gå till Coop.se
                    </a>
                </p>
                <p className="u-textCenter u-marginT">
                    <a href="/logga-ut?returnUrl=/">Logga ut</a>
                </p>
            </footer>
        </>
    );
};

export { LoggedIn };
