import React from 'react';
import { getCoopseUrl } from '../../common/utils';
import { AppContext } from '../../pages/App';
import '../Button/Button.css';

const AccountRemoved = () => {
    const context = React.useContext(AppContext);
    React.useEffect(() => {
        document.title = 'Coop | Kontot borttaget';
    }, []);

    React.useEffect(() => {
        context?.setShowNavigation(false);
    }, [context]);

    const coopSeUrl = getCoopseUrl('accountRemoved');

    // redirect to www.coop.se after 5 seconds
    window.setTimeout(function () {
        window.location.href = coopSeUrl;
    }, 5000);

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Kontot borttaget</h1>
            <p>Din användare på coop.se har nu raderats.</p>
            <br />
            <a className="Button Button--green" href={coopSeUrl}>
                Till www.coop.se
            </a>
        </>
    );
};

export { AccountRemoved };
