import { Route, Routes } from 'react-router-dom';
import EmailAlreadyConnected from './upptagen-epost/EmailAlreadyConnected';
import { RegisterEmail } from './registrera-epost/RegisterEmail';
import { VerifyEmailExistingAccount } from './upptagen-epost/verifiera-epost/VerifyEmailExistingAccount';
import Authenticate from './Authenticate';
import EmailNew from './ny-epost/EmailNew';
import { VerifyNewEmail } from './ny-epost/verifiera-epost/VerifyNewEmail';

const CreateAccountBankid = () => (
    <Routes>
        <Route path="/upptagen-epost/:email/verifiera" element={<VerifyEmailExistingAccount />} />
        <Route path="/upptagen-epost/:email" element={<EmailAlreadyConnected />} />
        <Route path="/ny-epost/:email" element={<EmailNew />} />
        <Route path="/ny-epost/:email/verifiera" element={<VerifyNewEmail />} />
        <Route path="/registrera-epost" element={<RegisterEmail />} />
        <Route path="/*" element={<Authenticate />} />
    </Routes>
);

export default CreateAccountBankid;
