import React from 'react';
import { useForm } from 'react-hook-form';
import CheckIcon from '../../../icons/check.svg?react';
import '../../../components/Form/Form.css';
import '../../../components/List/List.css';
import { ApiException, AuthenticationStateResult } from '../../../common/api/api.types';
import { getCoopseLoginUrl } from '../../../common/utils';
import { isExternalUserCompany } from '../../../core/authState.utils';
import { useMutation } from '@tanstack/react-query';
import useLoginNavigate, { SpaRoutes } from '../../../hooks/useLoginNavigate';
import { Loader } from '../../../components/Loader/Loader';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { accountApi } from '../../../common/api/api.accounts';
import useLoginContext from '../../../hooks/useLoginContext';

interface RememberMeProps {
    rememberMeHeader?: string;
    authState: AuthenticationStateResult;
}

const RememberMe = (props: RememberMeProps) => {
    const { register, handleSubmit, formState } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });

    const returnUrl = props.authState.loginRequest?.returnUrl || getCoopseLoginUrl();
    const navigate = useLoginNavigate();
    const context = useLoginContext();

    const signInMutation = useMutation<void, ApiException, boolean>({
        mutationFn: (rememberMe: boolean) =>
            accountApi.signinApplicationWithExternalSchema(rememberMe),
        onSuccess: () => {
            window.location.href = decodeURIComponent(returnUrl);
        },
        onError: (error) => {
            if (error.code === 'NO_ACCOUNT') {
                navigate(SpaRoutes.CreateAccount.Private.BankId.RegisterEmail);
            }
            if (error.code === 'RESTART') {
                navigate(SpaRoutes.Login.Start);
            }
        },
    });

    const handleRememberMeFormSubmit = (formData: { rememberMe?: boolean }) => {
        signInMutation.mutate(!!formData.rememberMe);
    };

    React.useEffect(() => {
        context?.setShowNavigation(false);
        // issues here with multiple refetch
        if (
            context?.bankIdOnly &&
            !signInMutation.submittedAt &&
            !signInMutation.isSuccess &&
            !signInMutation.isError &&
            !!props.authState.currentExternalUser
        ) {
            signInMutation.mutate(false);
        }
    }, [context, returnUrl, signInMutation.isSuccess]);

    const handleCancel = () => {
        window.location.href = getCoopseLoginUrl();
    };
    const isLoading = signInMutation.isPending || signInMutation.isSuccess;
    if (context?.bankIdOnly) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">{props.rememberMeHeader}</h1>
                <form className="Form" onSubmit={handleCancel}>
                    <div className="u-marginAutoZero">
                        <Loader className="u-marginTmd" />
                        <p className="u-textCenter">Loggar in...</p>
                    </div>

                    {signInMutation.isError && (
                        <p className="Notice Notice--red">{signInMutation.error.friendlyMessage}</p>
                    )}
                </form>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Fortsätt vara inloggad?</h1>
            <p className="u-textCenter">Som inloggad på Coop.se kan du t. ex:</p>
            <ul className="List u-textCenter">
                {isExternalUserCompany(props.authState) ? (
                    <>
                        <li className="List-item">
                            <CheckIcon className="Icon Icon--small Icon--strokeGreen u-alignMiddle" />
                            &nbsp; <span>handla online</span>
                        </li>
                        <li className="List-item">
                            <CheckIcon className="Icon Icon--small Icon--strokeGreen u-alignMiddle" />
                            &nbsp; <span>spara varukorgen</span>
                        </li>
                        <li className="List-item">
                            <CheckIcon className="Icon Icon--small Icon--strokeGreen u-alignMiddle" />
                            &nbsp;{' '}
                            <span>
                                betala med faktura via <i>Inköpstjänsten</i>
                            </span>
                        </li>
                    </>
                ) : (
                    <>
                        <li className="List-item">
                            <CheckIcon className="Icon Icon--small Icon--strokeGreen u-alignMiddle" />
                            &nbsp; <span>se dina poäng</span>
                        </li>
                        <li className="List-item">
                            <CheckIcon className="Icon Icon--small Icon--strokeGreen u-alignMiddle" />
                            &nbsp; <span>spara recept</span>
                        </li>
                        <li className="List-item">
                            <CheckIcon className="Icon Icon--small Icon--strokeGreen u-alignMiddle" />
                            &nbsp; <span>handla online</span>
                        </li>
                    </>
                )}
            </ul>
            <>
                {!isExternalUserCompany(props.authState) && (
                    <p className="u-textCenter u-textXSmall">
                        Du kommer att behöva identifiera dig med BankID när du vill göra poängköp,
                        banktjänster osv.
                    </p>
                )}
                <form className="Form" onSubmit={handleSubmit(handleRememberMeFormSubmit)}>
                    <Checkbox
                        id="rememberMe"
                        className="u-marginTlg u-marginBz"
                        label={
                            isExternalUserCompany(props.authState)
                                ? 'Ja, håll mig inloggad!'
                                : 'Ja, håll mig inloggad! Vid behov identifierar jag mig med BankID.'
                        }
                        formState={formState}
                        checked={true}
                        {...register('rememberMe')}
                    />
                    <footer className="u-marginTauto">
                        {signInMutation.isError && (
                            <p className="Notice Notice--red">
                                {signInMutation.error.friendlyMessage}
                            </p>
                        )}
                        {isLoading && <Loader className="u-marginTmd" />}
                        <button
                            className="Button Button--green u-marginT"
                            type="submit"
                            disabled={isLoading}
                        >
                            Bekräfta
                        </button>
                    </footer>
                </form>
            </>
        </>
    );
};

export default RememberMe;
