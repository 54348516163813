export default function dispatchEvent(name, payload) {
    const message = {
      event: name,
      payload,
      };
      
      if (window.webkit?.messageHandlers?.observer?.postMessage) {
          window.webkit.messageHandlers.observer.postMessage(message);
          return true;
      } else if (window.Android?.postMessage) {
          window.Android.postMessage(JSON.stringify(message));
          return true;
      } else if (window.ReactNativeWebView?.postMessage) {
          window.ReactNativeWebView.postMessage(JSON.stringify(message));
          return true;
      } else if (window?.postMessage) {
          window?.postMessage(JSON.stringify(message))
      }
      return false;
  }
  