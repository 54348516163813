import { useEffect, useState } from 'react';
import Search from './Search/Search';
import Wrapper from './style';
import UserList from './UserList/UserList';
import punchoutApi from '../api/punchoutApi';
import useDebounce from '../../../hooks/useDebounce';
import LineLoader from './LineLoader/LineLoader';
import Pagination from './Pagination/Pagination';
import { AuthenticationStateResult } from '../../../common/api/api.types';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';

const PUNCHOUT_SEACH_CUSTOMERS_QUERY_KEY = 'getCustomers';
const PAGE_SIZE = 10;
const PunchoutSignin = (props: { authState: AuthenticationStateResult }) => {
    const [query, setQuery] = useState('');
    const redirectUrl = props.authState.loginRequest?.returnUrl;
    const debouncedQuery = useDebounce(query, 500);
    const [page, setPage] = useState(1);
    const [loginUser, setLoginUser] = useState(false);
    const { error, data, isFetching } = useQuery({
        queryKey: [PUNCHOUT_SEACH_CUSTOMERS_QUERY_KEY, debouncedQuery, page],
        queryFn: () => punchoutApi.getCustomers(debouncedQuery, page - 1, PAGE_SIZE),
        refetchOnMount: false,
        placeholderData: keepPreviousData,
        retry: false,
        staleTime: 10 * (60 * 1000), // 10 min
    });

    const {
        isError: selectError,
        isSuccess: selectSuccess,
        mutate: select,
    } = useMutation({
        mutationFn: (customerId: string) => punchoutApi.loginCustomer(customerId),
    });

    useEffect(() => {
        if (selectSuccess && !loginUser && redirectUrl) {
            setLoginUser(true);
            window.location.href = redirectUrl;
        }
    }, [selectSuccess, loginUser, setLoginUser, redirectUrl]);

    const onSearch = (q: string) => {
        if (q.length >= 3) {
            setQuery(q);
        } else if (query !== '') {
            setQuery('');
        }
    };

    return (
        <Wrapper>
            <>
                <div>
                    <h2>Välkommen till Coops e-handel!</h2>
                    <p>
                        Sök för att hitta den enhet du vill skapa en order för och klicka sedan på
                        “Logga in” för din valda enhet. Observera att du måste välja samma enhet som
                        du ska skapa en order för i ditt inköpssystem. Hittar du inte din enhet,
                        vänligen kontakta vår&nbsp;
                        <a
                            href="https://www.coop.se/coop-kundservice/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            kundservice
                        </a>
                        .
                    </p>
                </div>
                {error && <h2>Fail</h2>}
                <div>
                    <Search autoComplete onSearch={onSearch} />
                    <LineLoader isLoading={isFetching} />
                </div>

                <UserList data={data?.customers} onClick={(id: string) => select(id)} />
                <Pagination
                    currentPage={page}
                    hideIfOnlyOnePage
                    onPageChange={(e, page) => {
                        e.preventDefault();
                        setPage(page.pageNumber);
                    }}
                    totalResults={data?.totalCount || 0}
                    resultsPerPage={PAGE_SIZE}
                    paginationRange={7}
                />
                {selectError && (
                    <h2 style={{ color: '#F00' }}>Something went wrong when trying to sign in</h2>
                )}
            </>
        </Wrapper>
    );
};

export { PunchoutSignin };
