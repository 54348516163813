import { useWindowSize } from '../../hooks/useWindowSize';
import { useBankid } from './BankId.hooks';
import { getBankIdUrl } from '../../common/utils';
import { QRCodeSVG } from 'qrcode.react';

const BankId = (
    props: Omit<ReturnType<typeof useBankid>, 'onSuccess' | 'isCompleted'> & {
        disabled?: boolean;
    },
) => {
    const windowSize = useWindowSize();
    const desktopScreen = windowSize.width > 1024;
    if (props.isStarted) {
        return (
            <>
                {!!props.autoStartToken && !props.qrCode && (
                    <div className="u-textCenter  u-marginT">
                        <h3>Startar inte BankID?</h3>
                        <a className="Link" href={getBankIdUrl(props.autoStartToken)}>Starta manuellt</a>
                    </div>
                )}
                {!!props.qrCode && (
                    <div className="u-textCenter  u-marginT">
                        <QRCodeSVG width={256} height={256} value={props.qrCode} />
                    </div>
                )}

                {props.message && <p className="u-textCenter u-marginT">{props.message}</p>}
                {props.isCollecting && (
                    <button
                        className="Button Button--invertedGreenDark u-marginTmd"
                        key="cancelButton"
                        onClick={props.cancel}
                    >
                        Avbryt
                    </button>
                )}
            </>
        );
    }

    return (
        <>
            {props.errorMessage && <p className="u-textCenter u-marginT">{props.errorMessage}</p>}
            {desktopScreen ? (
                <>
                    <button
                        className="Button Button--ferm-green u-marginTmd"
                        disabled={props.isStarted || props.disabled}
                        onClick={() => props.start('thisDevice')}
                    >
                        BankID på den här datorn
                    </button>
                    <button
                        className="Button Button--green u-marginTmd"
                        disabled={props.isStarted || props.disabled}
                        onClick={() => props.start('otherDevice')}
                    >
                        BankID på en annan enhet
                    </button>
                </>
            ) : (
                <>
                    <button
                        className="Button Button--green u-marginTmd"
                        disabled={props.isStarted || props.disabled}
                        onClick={() => props.start('thisDevice')}
                    >
                        Starta Mobilt BankID
                    </button>
                    <button
                        className="Button Button--ferm-green u-marginTmd"
                        disabled={props.isStarted || props.disabled}
                        onClick={() => props.start('otherDevice')}
                    >
                        BankID på en annan enhet
                    </button>
                </>
            )}
        </>
    );
};

export default BankId;
