import "./InformationBox.css"
import InfoIcon from '../../icons/info2.svg?react';

interface InformationBoxProps {
    text: React.ReactNode;
}

const InformationBox = ({ text }: InformationBoxProps) => {
  return (
    <div className="informationbox u-marginTlg u-marginB">
        <div className="informationbox-iconcontainer"><InfoIcon className="informationbox-icon" fill="#005537" title="Information icon" /></div>
          <div className="informationbox-text">{text}</div>
    </div>
  )
}

export default InformationBox
