import { AuthenticationStateResult } from '../../common/api/api.types';

const useExternalUser = (authState: AuthenticationStateResult) => {
    const givenName = authState.currentExternalUser?.claims.find(
        (c) => c.type === 'given_name',
    )?.value;
    const familyName = authState.currentExternalUser?.claims.find(
        (c) => c.type === 'family_name',
    )?.value;
    const email = '';
    return { email, givenName, familyName };
};

export default useExternalUser;
